<template>
    <v-card elevation="2" outlined class="mx-4 mt-4 mb-4">
        <slot></slot>
    </v-card>
</template>

<script>
export default {
    name: "app-card"
}
</script>
